import React from 'react';
import {
  Typography,
  Button
 } from "@material-ui/core";
 import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'react-lottie';
import animationData from '../assets/errorAnimation.json';

const FormError = ({ onReturn }) => {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className={classes.textWrapper}>
      <Lottie
        options={defaultOptions}
      />
      <Typography
        align="center"
        color="primary"
        variant="h4"
      >
        Something went wrong!
      </Typography>

      <Typography
        align="center"
        color="secondary"
        variant="body1"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onReturn}
      >
        Try again
      </Button>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    padding: 20,
    width: "100%",
    maxWidth: "30rem",
    margin: "auto",
    textAlign: "center"
  },
  item: {
    margin: "auto",
    width: "30rem"
  },
  button: {
    display: "block",
    margin: "auto",
    minWidth: 150,
    marginTop: 20
  }
}));

export default FormError;


