import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../assets/logo.svg';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center"

  }
}));

function Header() {
  const classes = useStyles();
  return (
    <AppBar position="static">
      <Toolbar>
        <a className={classes.grow} href="https://www.jamespauloneill.ie">
          <img src={logo} alt="JP" />
        </a>
      </Toolbar>
    </AppBar>
  );
}

export default Header;