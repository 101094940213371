import React from "react";
import Header from "../components/header.component";
import {
  CssBaseline,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { DropzoneArea } from "material-ui-dropzone";
import FormError from "../components/formError.component";
import FormSuccess from "../components/formSuccess.component";

const FormState = {
  READY: "READY",
  SENDING: "SENDING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

const Form = (props) => {
  const classes = useStyles();

  const [formState, setFormState] = React.useState(FormState.READY);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNo, setPhoneNo] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [birthday, setBirthday] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [clinic, setClinic] = React.useState("");
  const [attachments, setAttachments] = React.useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    setFormState(FormState.SENDING);

    let token;
    try {
      token = await window.grecaptcha.execute(
        "6LexRPIUAAAAAINbUydMYTh73N9Zgl-E4GKoWU5e",
        { action: "social" }
      );
    } catch {
      setFormState(FormState.ERROR);
      return;
    }
    let response;
    try {
      response = await fetch("https://contact-api.mova.digital/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reCaptchaResponse: token,
          firstName,
          lastName,
          phoneNo,
          address,
          birthday,
          email: email === "" ? undefined : email,
          clinic,
          attachments: attachments.map((a) => ({
            name: a.name,
            type: a.type,
          })),
        }),
      });
    } catch {
      setFormState(FormState.ERROR);
      return;
    }
    if (response.status !== 200) {
      setFormState(FormState.ERROR);
      return;
    }
    let { uploadUrls } = await response.json();
    for (let i = 0; i < attachments.length; i++) {
      let file = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = async (e) => {
          resolve(e.target.result);
        };
        fileReader.readAsArrayBuffer(attachments[i]);
      });
      try {
        await fetch(uploadUrls[i], {
          method: "PUT",
          body: new Blob([file], { type: attachments[i].type }),
        });
      } catch {
        setFormState(FormState.ERROR);
        return;
      }
    }

    setFormState(FormState.SUCCESS);
  };

  if (formState === FormState.ERROR)
    return (
      <div>
        <Header />
        <FormError onReturn={() => setFormState(FormState.READY)} />
      </div>
    );

  if (formState === FormState.SUCCESS)
    return (
      <div>
        <Header />
        <FormSuccess />
      </div>
    );

  return (
    <div>
      <Header />
      <CssBaseline />
      <div className={classes.textWrapper}>
        <Typography color="primary" variant="h4">
          Appointments
        </Typography>

        <Typography color="secondary" variant="body1">
          You can submit your referral letter/GP letter here. The appointments
          centre will be in touch once your letter has been reviewed by Prof
          O’Neill.
        </Typography>
      </div>

      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          fullWidth
          required
          className={classes.input}
          label="First name"
          variant="outlined"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          disabled={formState !== FormState.READY}
        />
        <TextField
          fullWidth
          required
          className={classes.input}
          label="Last name"
          variant="outlined"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
          disabled={formState !== FormState.READY}
        />
        <TextField
          fullWidth
          required
          className={classes.input}
          label="Telephone number"
          variant="outlined"
          value={phoneNo}
          onChange={(e) => setPhoneNo(e.target.value)}
          disabled={formState !== FormState.READY}
        />
        <TextField
          fullWidth
          required
          multiline
          className={classes.input}
          label="Address"
          variant="outlined"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          disabled={formState !== FormState.READY}
        />
        <TextField
          fullWidth
          required
          className={classes.input}
          label="Birthday (DD/MM/YYYY)"
          variant="outlined"
          value={birthday}
          onChange={(e) => setBirthday(e.target.value)}
          disabled={formState !== FormState.READY}
        />
        <TextField
          fullWidth
          className={classes.input}
          label="Email address"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          disabled={formState !== FormState.READY}
        />
        <FormControl variant="outlined" className={classes.input} required>
          <InputLabel id="clinic-label">Clinic Location</InputLabel>
          <Select
            fullWidth
            labelId="clinic-label"
            label="Clinic Location*"
            value={clinic}
            onChange={(e) => setClinic(e.target.value)}
            disabled={formState !== FormState.READY}
          >
            <MenuItem value={"Beaumont Private Clinic"}>
              Beaumont Private Clinic
            </MenuItem>
            <MenuItem value={"Blackrock Clinic"}>Blackrock Clinic</MenuItem>
          </Select>
        </FormControl>
        <DropzoneArea
          dropzoneClass={classes.dropzone}
          maxFileSize={6000000}
          filesLimit={3}
          acceptedFiles={[
            ".pdf",
            ".doc",
            ".docx",
            ".png",
            ".tif",
            ".tiff",
            ".jpg",
            ".jpeg",
          ]}
          onChange={
            formState === FormState.READY
              ? (files) => setAttachments(files)
              : null
          }
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={formState !== FormState.READY}
          type="submit"
        >
          {formState === FormState.READY ? "Submit" : "Submitting..."}
        </Button>
      </form>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    padding: 20,
    width: "100%",
    maxWidth: "30rem",
    margin: "auto",
    textAlign: "center",
  },
  form: {
    display: "block",
    padding: 20,
    paddingTop: 0,
    width: "100%",
    maxWidth: "30rem",
    margin: "auto",
  },
  inputWrapper: {
    width: "100%",
    textAlign: "center",
  },
  input: {
    display: "block",
    width: "100%",
    marginBottom: 10,
  },
  buttonWrapper: {
    margin: "1.5rem",
  },
  dropzone: {
    minHeight: 150,
    marginBottom: 20,
    borderRadius: 5,
    borderWidth: 1,
  },
  button: {
    display: "block",
    margin: "auto",
    minWidth: 150,
  },
}));

export default Form;
