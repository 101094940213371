import React from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import Lottie from 'react-lottie'
import animationData from '../assets/sendAnimation.json'
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  textWrapper: {
    width: "100%",
    display: "flex",
    padding: "3rem 0rem 1.5rem 0rem"
  },
  item: {
    margin: "auto",
    width: "30rem"
  },
  buttonWrapper: {
    margin: "auto",
    padding: "1.5rem"
  }
}));

function FormSuccess() {
  const classes = useStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (

    <Container maxWidth="lg" styles={{margin: "100px"}}>
      <Lottie options={defaultOptions}
        height={400}
        width={400}>
      </Lottie>

      <div className={classes.textWrapper}>
        <Box 
        className={classes.item}
        display="flex" 
        flexDirection="column"
        alignSelf="center"
        >
          <Typography
            align="center"
            color="primary"
            variant="h4"
          >
            Great Success!
          </Typography>

          <Typography
            align="center"
            color="secondary"
            variant="body1"
          >
            The appointments centre will be in touch once your letter has been reviewed by Professor O'Neill.
          </Typography>

          <div className={classes.buttonWrapper}>
            <Button href="https://www.jamespauloneill.ie" variant="contained" color="primary">
              Return to site
            </Button>
          </div>
        </Box>
      </div>
    </Container>
  );
}

export default FormSuccess;


